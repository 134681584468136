import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CustomButton from '../CustomBtn';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';
import x from "../../../assets/images/blackX.png"
import tg from "../../../assets/images/blackTg.png"
import { DataImage } from '../../Styles/style';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <CustomButton
        bgc="#fff"
        w="40px"
        p="0 8px 0px 0"
        sx={{ display: "flex", justifyContent: "space-between" }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {open ? (
          <CloseIcon
            sx={{
              marginRight: "8px",
              color: "#fff",
              background: "#000",
              borderRadius: "19px",
              fontSize: "30px",
              padding: "5px",
            }}
          />
        ) : (
          <MenuIcon
            sx={{
              marginRight: "8px",
              color: "#fff",
              background: "#000",
              borderRadius: "19px",
              fontSize: "30px",
              padding: "5px",
            }}
          />
        )}
        {open ? "Close" : "Menu"}
      </CustomButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
          <Link href="#home" sx={{textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
          Home
        </MenuItem>
          </Link>
          <Link href="#what" sx={{textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
          What?
        </MenuItem>
          </Link>
          <Link href="#why" sx={{textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
          Why?
        </MenuItem>
          </Link>
          <Link href="#thothenomics" sx={{textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
          Thothenomics
        </MenuItem>
          </Link>
          <Link href="#how-to-buy" sx={{textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
          How to Buy
        </MenuItem>
          </Link>
          <Link href="https://t.me/ThepeCoin" sx={{display:{xs:"block",sm:"none"},textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
        <DataImage mw="20px" src={tg} />&nbsp;Telegram
        </MenuItem>
          </Link>
          <Link href="https://x.com/ThepeCoin" sx={{display:{xs:"block",sm:"none"},textDecoration:"none"}}>
        <MenuItem sx={{fontFamily:"PatrickHand",color:"#000"}} onClick={handleClose}>
        <DataImage mw="16px" src={x} />&nbsp;Twitter
        </MenuItem>
          </Link>
      </Menu>
    </div>
  );
}
