import React, { useEffect, useRef, useState } from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import heroVideo from "../../../../assets/images/heroVideo.mp4";
import heroVideoMobile from "../../../../assets/images/heroMobileVideo.mp4";
import herobg from "../../../../assets/images/herobg.jpg"; 
import mobilebg from "../../../../assets/images/mobile.png";
import heroImage from "../../../../assets/images/heroImage.png";
import LockerMenu from "../../LockerMenu";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn"; 
import SectionHeading from "../../SectionHeading";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; 


const ContinerWraper = styled(Box)`
  
  @media (max-width: 599px) {
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const InnerContinerWraper = styled(Container)`
  /* min-height: calc(100dvh - 90px); */
  width: 100%;
  padding: 0 20px !important;
  /* z-index: 1; */
    display: flex;
    flex-direction: column;
    justify-content: start;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */

    background-image: none;
  }
`;
const HeroBgVideo = styled.video`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;    
  display : block;
  @media (max-width: 599px) {
    display : none;
  }
`;
const HeroBgVideoMobile = styled.video`
  width: 90%;
  margin: 0 auto;
  height: auto;
  z-index: -1;    
  display : none;
  @media (max-width: 599px) {
    display : block;
  }
`;
const Dataimg = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")}; 
  display: block;
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {  
    display: none;
    object-fit: cover;
  }
` 
const Mobilebg = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  margin: ${(props) => (props.m ? props.m : "0px")}; 
  display: none;
  @media (max-width: 899px) {
    max-width: ${(props) => (props.mw899 ? props.mw899 : "")};
  }
  @media (max-width: 599px) {  
    display: block;
    object-fit: cover;
  }
` 
const HeroSection = () => {  
  const [show, setShow] = useState(false);
  const addressToCopy = "00000000000000Xx0000x0000xxxx0";

  const handleCopy = () => {
    navigator.clipboard.writeText(addressToCopy);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };
   

  return (
    <ContinerWraper position={"relative"}>
        <DataImage src={heroImage} style={{position:"absolute",top:"-50px",left:"0",zIndex:"1"}}/>
      <Container id="home" position="relative" maxWidth="xl" sx={{zIndex:"2"}}>
        <LockerMenu />
        <InnerContinerWraper  maxWidth="xl">
          <CustomGrid container fd="column" ai="start" jc="start">
            <CustomGrid
              // data-aos="fade-right"
              container
              item
              xs={12}
              fd="column"
              // marginTop={{xs:"400px",sm:"0"}}
            >
             <SectionHeading normalText="Welcome to" boldText="Thepe Coin!"/>
              <Paragraph textAlign="center"  color="#000" fontSize={{xs:"28px",sm:"40px",md:"45px",xl:"70px"}}>
                The Future of Crypthocurrency... With a Thmile!
             </Paragraph>
              <Paragraph
              >
                Join Presale:
              </Paragraph>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </Container>
      <Box position="relative" width={"100%"} display={"flex"} justifyContent={"center"} minHeight={{xs:"500px",sm:"auto"}}>
        <Dataimg src={herobg}/> 
        <Mobilebg src={mobilebg} />
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} pl={"10px"} minWidth={"300px"} maxWidth={"400px"} sx={{minHeight:"40px",borderRadius:"20px",background:"#fff",border:"2px solid #000",position:"absolute",bottom:"20px",right:"50%",transform:"translate(50%,0px)"}}>
          { show ?
            <Paragraph color="#000" >
            Copied
          </Paragraph> :
          <Paragraph color="#000" >
            CA: 000xX00000X000xxxxxX
          </Paragraph>
          }
          <CustomButton p="7px" w="40px" bgc="#000" c="#fff">
            <ContentCopyIcon sx={{fontSize:"20px"}} onClick={handleCopy}/>
          </CustomButton>
        </Box>
      </Box>
    </ContinerWraper>
  );
};

export default HeroSection;
