import React from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import aboutLayer from "../../../../assets/images/aboutLayer.png";
import aboutLayerMobile from "../../../../assets/images/aboutLayerMobile.png";
import Paragraph from "../../Paragraph";
import backgroundImage from "../../../../assets/images/aboutBg.jpg";
import aboutBottom from "../../../../assets/images/aboutBottom.jpg";
import dady from "../../../../assets/images/dady.png";
import baby from "../../../../assets/images/baby.png";
import CustomButton from "../../CustomBtn";
import zIndex from "@mui/material/styles/zIndex"; 
import SectionHeading from "../../SectionHeading";

const ContinerWraper = styled(Container)`
  overflow: hidden;
  /* min-height: 100vh; */
  width: 100%;
  padding: 60px 0 !important;
  position: relative;
  z-index: 0;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  background-blend-mode: overlay;
  @media (max-width: 599px) {
    /* min-height: auto; */
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: 100%;
  width: 100%;
  background-color: #05A444;
  border-radius: 20px;
  display: grid;
  place-items: center;
  /* z-index: 1; */
  padding: 50px 50px !important;
  @media (max-width: 599px) {
    padding: 20px !important;
  }
  /* ::after{
    content: "";
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${aboutLayer});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    mix-blend-mode: overlay;
    display: none;
    @media(max-width: 599px){
      display: block;
    }
  } */
`;
const AboutImage = styled(DataImage)` 
  display : block;
  @media (max-width: 599px) {
    display : none;
  }
`;
const AboutImageMobile = styled(DataImage)`
  display : none;
  @media (max-width: 599px) {
    display : block;
  }
`;

const AboutSection = () => {
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="what">
        <InnerContinerWraper maxWidth="lg">
            <CustomGrid container jc="space-between" g="30px 0">
              <CustomGrid item fd="column" ai="start" jc="start"  xs={12} sm={6} g="10px 0">
                <SectionHeading  textAlign="left" fontSize={{xs:"50px",md:"90px"}} color="#fff"  normalText="What ith " boldText="Thepe Coin?"/>
                <Paragraph  fontSize={{xs:"18px",sm:"20px",md:"20px"}}>
                  Welthum to the world of Thepe Coin, the digital currenthy that'th here to revoluthonize the way you think about money! With Thepe Coin, we're not juth offering you another crypto; we're giving you a whole new way to thmile.
                </Paragraph>
                <CustomButton bgc="#fff" href="https://t.me/ThepeCoin" target="blank" c="#000" fs="28px" br="30px" p="2px 30px">
                  Join&nbsp;
                <Paragraph  mt="7px"  color="#000"  fontSize={{xs:"14px",sm:"28px"}}  fontFamily="GentyDemo" component='span'>
                    Thepe's
                  </Paragraph>&nbsp;Community
                </CustomButton>
              </CustomGrid>
              <CustomGrid item xs={12} sm={4}>
                <DataImage src={aboutBottom} style={{borderRadius:"40px"}}/>
              </CustomGrid>
            </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default AboutSection;
