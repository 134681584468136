import styled from "@emotion/styled";
import { Box, Container, Grid, Link } from "@mui/material";
import React from "react";
import { CustomGrid, DataImage } from "../../../Styles/style";
import FooterImage from "../../../../assets/images/FooterImage.png"; 
import x from "../../../../assets/images/x.png"  
import tg from "../../../../assets/images/tg.png"  
import cmBG from "../../../../assets/images/CommunityBg.png"  
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import SectionHeading from "../../SectionHeading"; 
import zIndex from "@mui/material/styles/zIndex";

const ContinerWraper = styled(Box)`
  overflow: hidden;
  min-height: 750px;
  width: 100%;
  position: relative;
  display: flex;
    flex-direction: column;
    justify-content: start;
  z-index: 0;  
  /* border-radius: 20px; */
  background: linear-gradient(to top,#05A444 70%, #fff 0%);
  @media (max-width: 599px) { 
    padding:0px 0px 50px;
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const InnerContinerWraper = styled(Container)`
  min-height: 400px;
  width: 100%;
  padding: 0 20px !important;
  /* z-index: 1; */
  background: #05A444;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  border: 3px solid #fff;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;
 
const ComuntyBg =  styled.img`
  position: absolute;  
  /* z-index: 0;  */
  left: 0; 
  bottom: 0; 
  width:100%;
`

const Community = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="community">
      <InnerContinerWraper maxWidth="lg" >
        <DataImage  style={{position:"absolute",top:"0%",right:"0%"}}  src={FooterImage} />
        <CustomGrid
          //  data-aos="fade-up"
            container
            p={{xs:"50px 0px",lg:"70px 50px 70px"}}
            fd="column" 
            ai="center" 
            js="start" 
            g="20px"
          >   
             <CustomGrid
                  item
                  xs={12}
                  fd="column"
                  ai="center"
                >
                  <SectionHeading normalText="Join the Revoluthion!" color="white" fontSize={{xs:"50px",lg:"90px"}} sx={{position:"relative",zIndex:"1000"}} /> 
                </CustomGrid>  
                <CustomGrid
                  item
                  xs={12}
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" fontSize={{xs:"18px",lg:"26px"}} maxWidth="900px" lineHeight={{xs:"20px",lg:"35px"}} textAlign="center" sx={{position:"relative",zIndex:"1000"}}>
                  You don'th need a reathon to be a part of Thepe. Join our communithy and get all your questhions answered in a thiff!
                  </Paragraph>
                </CustomGrid> 
             <CustomGrid
                  item
                  xs={12}
                  fd="row" 
                  js="center"
                  ai="center" 
                  g="10px"
                >  
                <Link href="https://x.com/ThepeCoin" target="blank" sx={{position:"relative",zIndex:'100'}} >  <DataImage mw="47px" mw599="47px !important" src={x} /></Link>
                <Link href="https://t.me/ThepeCoin" target="blank" sx={{position:"relative",zIndex:'100'}} >  <DataImage mw="47px" mw599="47px" src={tg} /></Link>
                <CustomButton fs599="21px" fs="24px" p="2px 20px" c="white" target="blank" >Buy Now</CustomButton>
                </CustomGrid> 
        </CustomGrid>
      </InnerContinerWraper>  
      <ComuntyBg src={cmBG} alt="communityBg" /> 
      <CustomGrid
          //  data-aos="fade-up"
            container
            p={{xs:"30px 30px 120px",lg:"70px 50px 30px"}}
            fd="column" 
            ai="center" 
            js="start" 
            // g={{xs:"15px",lg:"50px"}} 
            sx={{xs:"15px !important",lg:"30px !important"}}
          >   
             <CustomGrid
                  item
                  xs={12}
                  fd="column"
                  ai="center"
                >
                  <SectionHeading boldText="Thepe" color="white" fontSize={{xs:"50px",lg:"110px"}} /> 
                </CustomGrid>  
                <CustomGrid
                  item
                  xs={12}
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" fontSize={{xs:"18px",lg:"28px"}} textAlign="center" sx={{position:"relative",zIndex:"100"}}>
                  COPYRIGHT © 2024. ALL RIGHTS RESERVED.
                  </Paragraph>
                </CustomGrid> 
       
        </CustomGrid>
    </ContinerWraper>
  );
};

export default Community;
