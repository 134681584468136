import React from "react";
import logo from "../../../../assets/images/logo.png";
import x from "../../../../assets/images/x.png";
import tg from "../../../../assets/images/tg.png";
import { DesktopMainMenu, MenuLink } from "../styles";
import { DataImage } from "../../../Styles/style";
import BasicMenu from "../../Menu";
import { Typography } from "@mui/material";
const DesktopMenu = (props) => {
  return (
    <DesktopMainMenu maxWidth="xl">
      <BasicMenu/>
      <MenuLink disp="none" href="/" p="0px">
        <Typography color="#000" fontSize="32px" fontFamily="GentyDemo" >
            Thepe
        </Typography>
      </MenuLink>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.menuList.map((value, i) => (
          <MenuLink
          disp={value.display}
            key={i}
            href={value.link}
            target={value.target}
            className={value.customClass}
          >
            {value.title}
          </MenuLink>
        ))}
      </div>
    </DesktopMainMenu>
  );
};

export default DesktopMenu;
