import React, { useState } from "react";
import { Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid, DataImage } from "../../../Styles/style";
import backgroundImage from "../../../../assets/images/ToknomicsBG.jpg";
import RingChart from "../../../../assets/images/RingChart.png";
import dexscreener from "../../../../assets/images/dexscreener.png";
import dextools from "../../../../assets/images/dextools.png";
import uniswap from "../../../../assets/images/uniswap.png"; 
import ban from "../../../../assets/images/ban-icon.png";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn"; 
import SectionHeading from "../../SectionHeading";


const ContinerWraper = styled(Container)`
  overflow: hidden;
  min-height: 300px;
  width: 100%;
  background-color: #009017;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 70px 90px !important;
  /* position: relative;
  z-index: 0; */
  @media (max-width: 599px) {
    /* min-height: auto; */ 
    padding: 20px 30px !important; 
    min-height: 550px !important; 
  }
`;
const CircleGrid = styled(CustomGrid)`
  background-image: url(${RingChart});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 400px;
  margin:  0; 
  @media (max-width: 599px) {
    /* min-height: auto; */ 
    min-height: 550px !important;
  }
`;


const InnerContinerWraper = styled(Container)`
  background-color: #05A444;
  width: 100%;
  padding: 0 50px !important;  
  border-radius: 20px;
  @media (max-width: 599px) {
    min-height: 500px !important; 
    padding:50px 0px !important; 
  }
`;

const Tokenomics = () => {
  // const [showToast, setShowToast] = useState(false);
  // const addressToCopy = "00000000000000Xx0000x0000xxxx0";

  // const handleCopy = () => {
  //   navigator.clipboard.writeText(addressToCopy);
  //   setShowToast(true);
  //   setTimeout(() => {
  //     setShowToast(false);
  //   }, 2000);
  // };
  return (
    <>
      <ContinerWraper maxWidth="xxl" id="thothenomics">
        <InnerContinerWraper maxWidth="xl">
          <CustomGrid
          //  data-aos="fade-up"
            container
            p={{xs:"20px",lg:"40px 50px"}}
            fd="column"
          >
            <CustomGrid item xs={12} fd="column" g="15px 0">
            <SectionHeading normalText="Thothenomics" color="white" /> 
              <CustomGrid
                container
                item
                xs={12}
                md={8}
                // md={6}
                g="5px 0"
                fd="column" 
              >
                <CustomGrid
                  item
                  xs={12}
                  fd="column"
                  ai="center"
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" fontSize={{xs:"18px",lg:"50px"}} textAlign="center">
                  Token Supply: 100 billion
                  </Paragraph>
                </CustomGrid> 
                <CustomGrid
                  item
                  xs={12}
                  fd="column"
                  ai="center"
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" fontSize={{xs:"18px",lg:"50px"}} textAlign="center">
                  Name: Thepe Coin | Symbol: THEPE
                  </Paragraph>
                </CustomGrid> 
                <CustomGrid
                  item
                  xs={12}
                  fd="column"
                  ai="center"
                >
                  {/* <DataImage mw="50%" src={miniwagy} /> */}
                  <Paragraph variant="h6" textAlign="center" fontSize={{xs:"18px",lg:"50px"}} sx={{whiteSpace:"nowrap"}}>
                   Zero Percent Taxes, No Bullshit. It’s That Simple.
                  </Paragraph>
                </CustomGrid>
              </CustomGrid> 
              {/* <CustomGrid
                // data-aos-duration="2800"
                container
                item
                xs={12}
                sm={10}
                md={6}
                p="10px 20px"
                ai="start"
                jc="space-between"
                sx={{
                  background: "#AAFE02",
                  boxShadow: "4px 4px 0px #000",
                  borderRadius: "10px",
                  position: "relative",
                }}
              >
                {showToast && (
                  <div
                    style={{
                      width: "300px",
                      height: "40px",
                      borderRadius: "10px",
                      position: "absolute",
                      top: "-12%",
                      left: "50%",
                      transform: "translate(-50%,-12%)",
                      display: "grid",
                      placeItems: "center",
                      background: "#000",
                      color: "#007813",
                      zIndex: "1",
                    }}
                  >
                    Contract Address Copied
                  </div>
                )}
                <Paragraph fs="18px" fs599="16px" color="#007813">
                  Contract Address:
                </Paragraph>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap:"5px"
                  }}
                >
                  <Paragraph fs="18px" fs599="16px" color="#007813">
                    TBA
                  </Paragraph>
                  <FileCopyIcon sx={{color:"#007813",cursor:"pointer"}} onClick={handleCopy}/>
                </div>
              </CustomGrid>  */}
     
              <CustomGrid
                container
                item
                xs={12}
                sm={8}
                md={6}

                jc="center"
                g="10px"
              >
                <CustomButton bgc="#000000" c="white" fs="24px" fs599="16px" br="25px" b="2px solid white" target="blank" sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}} ><DataImage mw="30px" style={{marginRight:"5px"}} src={dexscreener} />DexScreener</CustomButton>
                <CustomButton bgc="#000000" c="white" fs="24px" br="25px" fs599="16px" b="2px solid white" target="blank" sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}} ><DataImage mw="25px" style={{marginRight:"5px"}} src={dextools} />DexTools</CustomButton>
                <CustomButton bgc="#000000" c="white" fs="24px" br="25px" fs599="16px" b="2px solid white" target="blank" sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}} ><DataImage mw="30px" style={{marginRight:"5px"}} src={uniswap} />Uniswap</CustomButton>

              </CustomGrid>
            </CustomGrid>
          </CustomGrid>
        </InnerContinerWraper>
      </ContinerWraper>
    </>
  );
};

export default Tokenomics;
