import React from "react";
import { Box, Container } from "@mui/material";
import styled from "@emotion/styled";
import { CustomGrid,  DataImage } from "../../../Styles/style";
import Paragraph from "../../Paragraph";
import Bg from "../../../../assets/images/whyBG.jpg";
import SectionHeading from "../../SectionHeading";


const ContinerWraper = styled(Container)`
  overflow: hidden;
  /* min-height: 100vh; */
  width: 100%;
  padding: 60px 0 !important;
  
`;

const InnerContinerWraper = styled(Container)`
  /* min-height: calc(100vh); */
  width: 100%;
  padding: 0 50px !important;
  display: grid;
  place-items: center; 

  z-index: 1;
  @media (max-width: 599px) {
    min-height: auto;
    background-position: left;
    padding: 0 20px !important;
    /* min-height: auto; */
    background-image: none;
  }
`;
const StyledParagraph = styled(Paragraph)`
  background:#065317;
  display: flex;
  align-items: center;
  gap:5px;
  border-radius: 10px;
  padding: 10px;
  width: 100%;

`;

const Why = () => {
  
  return (
    <>
      <ContinerWraper maxWidth="xl" id="why" >  
         <SectionHeading normalText="Why Choose" boldText="Thepe Coin?" fontSize={{xs:"55px",md:"90px",xl:"110px"}} />  
         <CustomGrid container p="0px 20px" g="20px" sx={{marginTop:{xs:"30px",lg:"50px"}}}> 
           <CustomGrid item xs={12} lg={5} >  
           <DataImage mw="100%" src={Bg} style={{borderRadius:"20px"}} />
            </CustomGrid>  
            <CustomGrid item xs={12} lg={5.2}  fd="column" jc="start" ai="start" g="20px" >    
              <Box sx={{backgroundColor:"#05A444", borderRadius:"20px", minHeight:"200px",padding:"30px 50px 30px 20px",  width:"100%",display:"flex", flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>  
                <Paragraph variant="h5" fontSize={{xs:"37px",lg:"50px"}} >   
                Thecure and Thafe
                </Paragraph>    
                <Paragraph variant="body2" fontSize={{xs:"18px",lg:"28px"}}>   
                Our encryption ith tho strong, even a ninja hacker can't crack it.
                </Paragraph>
              </Box> 
              <Box sx={{backgroundColor:"#05A444", borderRadius:"20px",minHeight:"200px",padding:"30px 50px 30px 20px",width:"100%",display:"flex", flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>  
                <Paragraph variant="h5" fontSize={{xs:"37px",lg:"50px"}}>   
                Fatht Tranthactionth
                </Paragraph>    
                <Paragraph variant="body2" fontSize={{xs:"18px",lg:"28px"}} >   
                Thpeedy tranthfers that leave other coins in the dutht.
                </Paragraph>
              </Box> 
              <Box sx={{backgroundColor:"#05A444", borderRadius:"20px",minHeight:"200px",padding:"30px 50px 30px 20px",width:"100%",display:"flex", flexDirection:"column",justifyContent:"start",alignItems:"start",gap:"5px"}}>  
                <Paragraph variant="h5" fontSize={{xs:"37px",lg:"50px"}}>  
                    Fun and Engaging
                </Paragraph>    
                <Paragraph variant="body2" fontSize={{xs:"18px",lg:"28px"}} >  
                    Every tranthaction ith a fun adventure. You never know what Thepe Coin might do next!
                </Paragraph>
              </Box>
            </CustomGrid>
          </CustomGrid>
         
      
       
      </ContinerWraper>
    </>
  );
};

export default Why;
