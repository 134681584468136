import React from "react";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import { Container } from "@mui/material";
import x from "../../../assets/images/x.png"
import tg from "../../../assets/images/tg.png"
import { DataImage } from "../../Styles/style";
import CustomButton from "../CustomBtn";

const LockerMenu = (props) => {
  const menuList = [
    // You can pass 4 perameters for menu title, link, target and customClass
    
    {
      title:<DataImage mw="40px" src={x} />,
      link: "https://x.com/ThepeCoin",
      target: "blank",
      display:"none"
    },
    {
      title:<DataImage mw="40px" src={tg} />,
      link: "https://t.me/ThepeCoin",
      target: "blank",
      display:"none"
    },
    {
      title:<CustomButton c="white" target="blank" >Buy Now</CustomButton>,
      link: "",
      target: "blank"
    },
  ];
  return (
        <Container maxWidth="xl" sx={{padding:"0 !important"}}>
          <DesktopMenu menuList={menuList} />
          {/* <MobileMenu menuList={menuList} /> */}
        </Container>
  );
};

export default LockerMenu;
