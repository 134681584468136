import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
import React from "react";
import { CustomGrid, DataImage } from "../../../Styles/style";
import pinksale from "../../../../assets/images/pinksale.png";

import logoleft from "../../../../assets/images/logoleft.png";
import logoright from "../../../../assets/images/logoright.png";
import babyborpa from "../../../../assets/images/babyborpa.png";
import Paragraph from "../../Paragraph";
import CustomButton from "../../CustomBtn";
import Countdown from "react-countdown";
const ContinerWraper = styled(Container)`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  z-index: 0;
  background: #007B15;
  padding: 100px 0 !important;
  @media (max-width: 599px) {
    min-height: auto;
  }
`;


const HowToBuy = () => {
  return (
    <ContinerWraper maxWidth="xxl" id="presale">
      <Container maxWidth="xl" >

        <Grid
        data-aos="fade-up"
          container
          sx={{
            display: "flex",
            justifyContent: "start",
            rowGap: "20px",
            zIndex: "1",
            // columnGap: "30px",
           
            
          }}
        >
             <Paragraph
                // data-aos="zoom-in"
                // variant="h1"
                fontSize="clamp(3.75rem, 3.0357rem + 3.5714vw, 6.25rem)"
                color="#AAFE02"
                fontWeight="bolder"
                fontFamily="GentyDemo"
                lineHeight={{xs:".1",sm:".3"}}
              >
              HOW TO BUY!
              </Paragraph>
          <CustomGrid
              container
              item
              xs={12}
              sm={8}
              ai="start"
              fd="column"
              g="20px"
            >
              <Paragraph
                // data-aos="zoom-in"
                variant="h6"
              >
               Buy your $BBPRPA <br /> exclusively on Pinksale!
              </Paragraph>
              <CustomButton sx={{filter:"grayscale(1)",cursor:"not-allowed !important"}}><DataImage mw="30px" style={{marginRight:"10px"}} src={pinksale} />Pinksale</CustomButton>
            </CustomGrid>
            
          <CustomGrid
              container
              item
              xs={12}
              md={4}
              ai="end"
              p="30px 0 0"
            >
             <Box bgcolor="#013B0A" sx={{width:"100%",maxWidth:{xs:"320px",sm:"430px"},position:"relative",padding:"20px",borderRadius:"10px",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",gap:"10px"}}>
              <DataImage mw="100px" style={{position:"absolute",top:"-40px",left:"-40px"}}  src={babyborpa} />
              <DataImage mw="120px" style={{position:"absolute",top:"-40px",right:"-40px"}}  src={logoright} />
              <DataImage mw="120px" style={{position:"absolute",bottom:"-40px",left:"-40px"}}  src={logoleft} />
              <Paragraph
                // data-aos="zoom-in"
                variant="h6"
              >
               Presale coming soon!
              </Paragraph>
              <Paragraph
                // data-aos="zoom-in"
                fontSize="clamp(3rem, 2.7143rem + 1.4286vw, 4rem)"
                color="#AAFE02"
                fontWeight="bolder"
                fontFamily="GentyDemo"
              >
               <Countdown date={Date.now() + 0} />
              </Paragraph>
              <Paragraph
                // data-aos="zoom-in"
              >
               Days - Hours - Mins - Secs
              </Paragraph>
             </Box>
            </CustomGrid>
        </Grid>
      </Container>
    </ContinerWraper>
  );
};

export default HowToBuy;
