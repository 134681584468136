import { Typography } from '@mui/material'
import React from 'react'


const SectionHeading = (props) => {
    const {normalText,boldText} = props
  return (
    <Typography lineHeight={0.9} fontSize={{xs:"60px",md:"90px",xl:"110px"}} color="#000" fontWeight={100} fontFamily="PatrickHand"  textAlign="center"  {...props}>
        {normalText} &nbsp;
        <Typography whiteSpace="nowrap" lineHeight={0.9} fontSize={{xs:"50px",md:"90px",xl:"110px"}} fontFamily="GentyDemo" component='span'  {...props}>
            {boldText}
        </Typography>
    </Typography>
  )
}

export default SectionHeading